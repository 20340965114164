import { Brand } from 'src/app/app.models';
import { Pipe, PipeTransform } from '@angular/core';
import { TypeResolverService } from 'src/app/services/type-resolver-service';

@Pipe({
  name: 'BrandImageResolver'
})
export class BrandImageResolverPipe implements PipeTransform {

  constructor(public typeResolverService: TypeResolverService) {
  }

  transform(brand: Brand) {
    var result: string;
    
    if (brand?.imageUrl) {
      result = this.typeResolverService.resolveServiceUrl(brand.imageUrl);
    }

    return result;
  }
}
