<div *ngIf="brands?.length > 0" class="section">

    <div class="theme-container">

        <div class="brands-carousel"> 
            <div class="swiper-container h-100" [swiper]="config"> 
                <div class="swiper-wrapper h-100">      
                    <div *ngFor="let brand of brands" class="swiper-slide">
                        <div class="client-item"> 
                            <img style="height: 60px;" [lazyLoad]="brand | BrandImageResolver" class="selectable" [routerLink]="['/products']" [queryParams]="{filterBrands: brand?.code}">
                        </div>
                    </div>
                </div> 
            </div>
        </div>
            
    </div>

</div>
