import { Component, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { Category, CategoryItem } from 'src/app/app.models';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CategoryChange } from '../interfaces/category-event.interface';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent {

  @Input() hasCheckbox: boolean = false;

  @Input() categories: Array<Category>;

  @Input() filterCategories: Array<string>;

  @Input() categoryParentId: number;

  @Output() change: EventEmitter<CategoryChange> = new EventEmitter();

  public categoryItems: Array<CategoryItem>;

  public mainCategoryItems: Array<CategoryItem>;

  constructor() { }

  // public ngDoCheck() {
  //   if (this.categoryItems && !this.mainCategoryItems) {
  //     this.mainCategoryItems = this.categoryItems.filter(category => category.parentId == this.categoryParentId);
  //   }
  // }

  public setChecked() {
    if (this.categoryItems?.length && this.filterCategories) {

      this.categoryItems.forEach(item => {
        item.isChecked = this.filterCategories.includes(item.code);
      });
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (changes?.categories?.currentValue) {

      this.categoryItems = changes.categories.currentValue.map((c: Category) => new CategoryItem(c));

      this.mainCategoryItems = this.categoryItems.filter(categoryItem => categoryItem.parentId == this.categoryParentId);
      
      this.setChecked();
    }

    if (changes?.filterCategories) {

      this.setChecked();
    }
  }

  public onChangeCategory(event: MouseEvent, categoryCode: string) {
    this.change.emit({event, categoryCode} as CategoryChange);
  }
}
