/**
 * Checks if `value` is `null` or `undefined`.
 *
 * @memberOf _
 * @since 4.0.0
 * @category Lang
 * @param value The value to check.
 * @returns Returns `true` if `value` is nullish, else `false`.
 * @example
 *
 * _.isNil(null);
 * // => true
 *
 * _.isNil(void 0);
 * // => true
 *
 * _.isNil(NaN);
 * // => false
 */
 export function isNil(value: any): boolean {

    /* tslint:disable:triple-equals */
    return value == null || value == undefined;
}

export function downLoadByteArrayByContentType(file: string, fileName: string, contentType: string) {
    const byteArray = new Uint8Array(atob(file).split('').map(char => char.charCodeAt(0)));

    const blob = new Blob([byteArray], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
