import { AppSettings, Settings } from 'src/app/app.settings';
import { Category, CategoryItem } from 'src/app/app.models';
import { Component, Input, Output, EventEmitter, SimpleChange, OnChanges } from '@angular/core';
import { CategoryChange } from '../interfaces/category-event.interface';

@Component({
  selector: 'app-category-chip-list',
  templateUrl: './category-chip-list.component.html',
  styleUrls: ['./category-chip-list.component.scss']
})
export class CategoryChipListComponent implements OnChanges {

  @Input() categories: Array<Category>;

  @Input() categoryParentId: number;

  @Input() filterCategories: Array<string>;

  @Output() change: EventEmitter<any> = new EventEmitter();

  public mainCategoryItems: Array<CategoryItem>;

  public settings: Settings;

  constructor(public appSettings: AppSettings) {
    this.settings = this.appSettings.settings; 
  }
  
  public setChecked() {
    if (this.mainCategoryItems?.length && this.filterCategories) {

      this.mainCategoryItems.forEach(item => {
        item.isChecked = this.filterCategories.includes(item.code);
      });
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (changes?.categories?.currentValue || changes?.filterCategories) {

      let categoryItems: Array<CategoryItem>;

      if (changes?.categories?.currentValue) {
        categoryItems = changes.categories.currentValue.map((c: Category) => new CategoryItem(c));
      }

      if (categoryItems) {
        this.mainCategoryItems = categoryItems.filter((categoryItem: CategoryItem) => categoryItem.parentId == this.categoryParentId);
      }

      this.setChecked();
    }
  }

  public remove(categoryCode: string) {
    this.change.emit({categoryCode} as CategoryChange);
  }
}
