export enum MessageSeverityType {

    None = 0,
    Success = 1,
    Info = 2,
    Error = 3,
    Warning = 4,
}

export class MenuItemTypeConverter {
    
    public static convert(value: any): MessageSeverityType {

        var result: MessageSeverityType = MessageSeverityType.None;

        if (value == 1 || value === MessageSeverityType.Success) {
            result = MessageSeverityType.Success;
        } else if (value == 2 || value === MessageSeverityType.Info) {
            result = MessageSeverityType.Info;
        } else if (value == 3 || value === MessageSeverityType.Error) {
            result = MessageSeverityType.Error;
        } else if (value == 4 || value === MessageSeverityType.Warning) {
            result = MessageSeverityType.Warning;
        }

        return result;
    } 
}