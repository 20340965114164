import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MessageSeverityType } from '../enums/message-severity-type.enum';

@Component({
    selector: 'message-presenter',
    templateUrl: './message-presenter.component.html',
    styleUrls: ['./message-presenter.component.scss'],
    providers: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagePresenter {

    public messageSeverityType = MessageSeverityType;

    @Input() message: string;

    @Input() visible: boolean;

    public get hasMessage(): boolean {
        return this.message?.length > 0;
    }

    private _severity: MessageSeverityType = MessageSeverityType.None;
    public get severity(): MessageSeverityType {
        return this._severity;
    }
    @Input() public set severity(value: MessageSeverityType) {
        if (value !== this._severity) {
            this._severity = value;
        }
    }

    public get matIcon(): string {
        let icon: string = null;

        if (this.severity) {

            switch (this.severity) {

                case MessageSeverityType.Error:
                    icon = 'error';
                    break;

                case MessageSeverityType.Warning:
                    icon = 'warning';
                    break;

                case MessageSeverityType.Info:
                    icon = 'info';
                    break;

                case MessageSeverityType.Success:
                    icon = 'check_circle';
                    break;
            }
        }

        return icon;
    }
}
