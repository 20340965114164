import { BasePage } from 'src/app/pages/base/base-page';
import { Category } from 'src/app/app.models';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MapperService } from 'src/app/services/mapper.service';
import { MenuItem } from 'src/app/shared/interfaces/menuitem.interface';
import { Router } from '@angular/router';
import * as appStateSelectors from '../../../redux/app.state';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends BasePage implements OnInit, OnDestroy {

    @ViewChild('menuItemsContainer', { read: ElementRef })
    private menuItemsContainer: ElementRef<HTMLElement>;

    menuItems: Array<MenuItem> = null;

    overflowingMenuItems: Array<any> = [];

    constructor(
        private cdr: ChangeDetectorRef,
        private mapperService: MapperService,
        private router: Router,) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(

            this.appState.select(appStateSelectors.categoriesSelector).subscribe((categories: Array<Category>) => {
                this.menuItems = categories ? categories.filter(c => c.id).map(c => this.mapperService.toCategoryMenuItem(c)) : null;
            }),
        );
    }

    // openMegaMenu(){
    //   let pane = document.getElementsByClassName('cdk-overlay-pane');
    //   [].forEach.call(pane, function (el) {
    //       if(el.children.length > 0){
    //         if(el.children[0].classList.contains('mega-menu')){
    //           el.classList.add('mega-menu-pane');
    //         }
    //       }        
    //   });
    // }

    private updateFilterMenuItemsDom() {

        this.overflowingMenuItems = [];

        const menuItemsContainerEl = this.menuItemsContainer?.nativeElement;

        let menuItemContainerWidth = menuItemsContainerEl?.offsetWidth;
        if (menuItemContainerWidth) {

            const childElementCount = menuItemsContainerEl.childElementCount;
            for (let i = 0; i < childElementCount; i++) {

                // When the last horizontal menu item becomes a vertical menu, two horizontal menu items are replaced by the new vertical menu.
                const child = menuItemsContainerEl.children[i] as HTMLElement;
                const prewChild = i ? menuItemsContainerEl.children[i - 1] as HTMLElement : null;

                // Calculate horizonal menu item text by canvas
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                const font = window.getComputedStyle(child).getPropertyValue('font');
                const text = child.textContent;
                context.font = font;
                let { width } = context.measureText(text);
                width += 49;

                // There is not enough space in the horizontal menu container if the last withdrawal has resulted in the container  running out of space.
                menuItemContainerWidth -= width;
                if (menuItemContainerWidth < 0) {

                    if (child.id && this.menuItems) {
                        const menuItem = this.menuItems[+child.id];
                        if (menuItem) {

                            child.style.display = 'none';
                            menuItem.order = +child.id;
                            this.overflowingMenuItems.push(menuItem);

                            if (this.overflowingMenuItems.length === 1 && prewChild && +child.id - 1 >= 0) {
                                const prevMenuItem = this.menuItems[+child.id - 1];
                                if (prevMenuItem) {

                                    prewChild.style.display = 'none';
                                    prevMenuItem.order = +child.id - 1;
                                    this.overflowingMenuItems.push(prevMenuItem);
                                }
                            }
                        }
                    }
                }
                else {
                    // Only the horizontal menu items have ids, not the menu.
                    if (+child.id) {
                        child.style.display = 'flex';
                        const menuItem = this.menuItems[+child.id];
                        if (menuItem && this.overflowingMenuItems) {

                            // This horizontal menu item cannot be included in the vertical menu items because it fits in the container
                            const overflowingMenuItemIndex = this.overflowingMenuItems.findIndex(om => om.id === menuItem.id);
                            if (overflowingMenuItemIndex > -1) {
                                this.overflowingMenuItems = [...this.overflowingMenuItems.slice(0, overflowingMenuItemIndex), ...this.overflowingMenuItems.slice(overflowingMenuItemIndex + 1)];
                            }
                        }
                    }
                }
            }

            // Set order of vertical menu items
            this.overflowingMenuItems = this.overflowingMenuItems.sort((tn1, tn2) => (tn1.order > tn2.order) ? 1 : ((tn2.order > tn1.order) ? -1 : 0));
            this.cdr.detectChanges();
        }
    }

    onSizeChange() {
        this.updateFilterMenuItemsDom();
    }

    onSelectCategoryMenu(menuItem: MenuItem) {
        if (menuItem) {
            this.router.navigate(menuItem.routerLink, { queryParams: menuItem.queryParams });
        }
    }
}
