<div *ngFor="let menu of parentMenu" class="menu-item">
    <a *ngIf="menu.routerLink && !menu.hasSubMenu" mat-button
        fxLayout="row" fxLayoutAlign="start center"
        [routerLink]="menu.routerLink" [queryParams]="menu.queryParams" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}"        
        (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <span class="menu-title">{{ menu.title | translate }}</span>
    </a>
    <a *ngIf="menu.href && !menu.subMenu" mat-button 
        fxLayout="row" fxLayoutAlign="start center"
        [attr.href]="menu.href || ''" [attr.target]="menu.target || ''"       
        (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <span class="menu-title">{{ menu.title | translate }}</span>
    </a>
    <a *ngIf="menu.hasSubMenu" mat-button 
        fxLayout="row" fxLayoutAlign="start center"       
        (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <span class="menu-title">{{ menu.title | translate }}</span>
        <mat-icon class="menu-expand-icon transition-2">arrow_drop_down</mat-icon>
    </a>

    <div *ngIf="menu.hasSubMenu" class="sub-menu" [id]="'sub-menu-'+menu.id">
        <app-sidenav-menu [menuItems]="menuItems" [menuParentId]="menu.id"></app-sidenav-menu>
    </div>
</div>