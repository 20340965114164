import { Component, Input } from '@angular/core';
import { MenuItem } from 'src/app/shared/interfaces/menuitem.interface';
import { SidenavMenuService } from './sidenav-menu.service';

@Component({
    selector: 'app-sidenav-menu',
    templateUrl: './sidenav-menu.component.html',
    styleUrls: ['./sidenav-menu.component.scss'],
    providers: [SidenavMenuService]
})
export class SidenavMenuComponent {

    parentMenu: Array<any>;

    private _menuItems: Array<MenuItem>;
    get menuItems(): Array<MenuItem> {
        return this._menuItems;
    }
    @Input() set menuItems(value: Array<MenuItem>) {
        this._menuItems = value;
        this.setParentMenu();
    };

    private _menuParentId: number;
    get menuParentId(): number {
        return this._menuParentId;
    }
    @Input() set menuParentId(value: number) {
        this._menuParentId = value;
        this.setParentMenu();
    };

    constructor(private sidenavMenuService: SidenavMenuService) { }

    private setParentMenu() {

        if (this.menuParentId !== undefined && this.menuItems?.length) {
            this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);
        }
    }

    onClick(menuId: number) {
        this.sidenavMenuService.toggleMenuItem(menuId);
        this.sidenavMenuService.closeOtherSubMenus(this.menuItems, menuId);
    }
}
