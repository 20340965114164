import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'src/app/app.models';
import { TypeResolverService } from 'src/app/services/type-resolver-service';

@Pipe({
  name: 'ProductBigImageResolver'
})
export class ProductBigImageResolverPipe implements PipeTransform {

  constructor(public typeResolverService: TypeResolverService) {
  }

  transform(product: Product, index: number = 0) {

    var result: string;

    if (product?.productImages?.length > 0) {
      const productImage = product.productImages[index];
      if (productImage) {
        result = productImage?.imageUrl;
        if (!result) {
          result = productImage?.thumbnailImageUrl;
        }
      }
    }

    if (!result) {
      result = product?.image;
    }  

    if (result) {
      result = this.typeResolverService.resolveServiceUrl(result);
    }

    return result;
  }
}
