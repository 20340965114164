import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'svg-util-close',
    templateUrl: './svg-util-close.component.html',
    styleUrls: ['./svg-util-close.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SvgUtilClose {
}