export enum ComparisonOperators {

    None = 0,
    Equal = 1,
    NotEqual = 2,
    Greater = 3,
    GreaterOrEqual = 4,
    Less = 5,
    LessOrEqual = 6,
    IsNull = 7,
    IsNotNull = 8,
    TextLike = 9,
    TextStartsWith = 10,
    TextEndsWith = 11,
    Contains = 12
}

export class ComparisonOperatorsConverter {

    public static convert(value: any): ComparisonOperators {

        let result: ComparisonOperators = ComparisonOperators.None;

        if (value === 1 || value === '1' || value === ComparisonOperators.Equal) {
            result = ComparisonOperators.Equal;
        } else if (value === 2 || value === '2' || value === ComparisonOperators.NotEqual) {
            result = ComparisonOperators.NotEqual;
        } else if (value === 3 || value === '3' || value === ComparisonOperators.Greater) {
            result = ComparisonOperators.Greater;
        } else if (value === 4 || value === '4' || value === ComparisonOperators.GreaterOrEqual) {
            result = ComparisonOperators.GreaterOrEqual;
        } else if (value === 5 || value === '5' || value === ComparisonOperators.Less) {
            result = ComparisonOperators.Less;
        } else if (value === 6 || value === '6' || value === ComparisonOperators.LessOrEqual) {
            result = ComparisonOperators.LessOrEqual;
        } else if (value === 7 || value === '7' || value === ComparisonOperators.IsNull) {
            result = ComparisonOperators.IsNull;
        } else if (value === 8 || value === '8' || value === ComparisonOperators.IsNotNull) {
            result = ComparisonOperators.IsNotNull;
        } else if (value === 9 || value === '9' || value === ComparisonOperators.TextLike) {
            result = ComparisonOperators.TextLike;
        } else if (value === 10 || value === '10' || value === ComparisonOperators.TextStartsWith) {
            result = ComparisonOperators.TextStartsWith;
        } else if (value === 11 || value === '11' || value === ComparisonOperators.TextEndsWith) {
            result = ComparisonOperators.TextEndsWith;
        } else if (value === 12 || value === '12' || value === ComparisonOperators.Contains) {
            result = ComparisonOperators.Contains;
        }

        return result;
    }
}
