import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'src/app/app.models';
import { TypeResolverService } from 'src/app/services/type-resolver-service';

@Pipe({
  name: 'ProductImageResolver'
})
export class ProductImageResolverPipe implements PipeTransform {

  constructor(public typeResolverService: TypeResolverService) {
  }

  transform(product: Product, firstBig: boolean, index: number = 0) {
    var result: string;

    if (firstBig) {

        if (product?.productImages?.length > 0) {
          const productImage = product.productImages[index];
          if (productImage) {
            result = productImage?.imageUrl;
          }

        } else {
          result = product?.image;
        }

    } else {
      result = product?.image;
    }

    if (result) {
      result = this.typeResolverService.resolveServiceUrl(result);
    }

    return result;
  }
}
