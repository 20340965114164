import { EnvService } from './env.service';
import { Injectable } from '@angular/core';
import { ServiceLocator } from '../services/locator.service';

@Injectable({
    providedIn: 'root',
})
export class TypeResolverService {

    private envService: EnvService;

    constructor() {
        this.envService = ServiceLocator.injector.get(EnvService);
    }

    public resolveServiceUrl(url: string): string {

        let result: string = url;

        if (url) {

            if (url.startsWith('~')) {
                result = url.replace('~', this.envService.litaliaApiUrl);
            }
        }

        return result;
    }
}
