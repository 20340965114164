export enum TableMetadataQueryTypes {

    MinMax = 0,
    ValueList = 1,
}

export class TableMetadataQueryTypesConverter {

    public static convert(value: any): TableMetadataQueryTypes {

        let result: TableMetadataQueryTypes = TableMetadataQueryTypes.MinMax;

        if (value === 1 || value === '1' || value === TableMetadataQueryTypes.ValueList) {
            result = TableMetadataQueryTypes.ValueList;
        }

        return result;
    }
}
