import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from 'src/app/config/application.config';

@Pipe({
  name: 'currencySign'
})
export class CurrencySignPipe implements PipeTransform {

  transform(price: number, currencyId: number | string): string {
    var result: string;

    currencyId = typeof currencyId === 'string' ? +currencyId : currencyId;

    switch(currencyId) {

        case Currency.EUR:
            result = `€ ${price}`;
        break;

        case Currency.USD:
            result = `$ ${price}`;
        break;

        case Currency.HUF:
            result = `${price} Ft`;
        break;
    }

    return result;
  }
}
