<div style="margin-top: 8px;">
    <div *ngFor="let category of mainCategoryItems">

        <div *ngIf="hasCheckbox; else noCheckbox" style="margin-left: 16px;">
            <mat-checkbox [(ngModel)]="category.isChecked" (change)="onChangeCategory($event, category.code)" color="primary">{{category.name}}</mat-checkbox>
        </div>
        <ng-template #noCheckbox>
            <button mat-menu-item (click)="onChangeCategory($event, category.code)">
                <span>{{category.name}}</span>
            </button>
        </ng-template>

        <div *ngIf="category.hasSubCategory" class="sub-category px-2">
            <app-category-list [categories]="categories" [categoryParentId]="category.id" (change)="onChangeCategory($event, category.code)"></app-category-list>
        </div>
    </div>
</div>