import { Action } from '../shared/interfaces/action.interface';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ActionService {

    private actionEmitter = new Subject<Action>();
    action$ = this.actionEmitter.asObservable();

    publishAction(id: string, arg: any = null, command: (event?: any) => void = null) {

        if (id) {
            this.actionEmitter.next({ id, arg, command });
        }
    }
}
